import Parser from "html-react-parser"
import React, { useEffect } from "react"
import * as styles from "./LeadooDynamicEmbedding.module.scss"

type LeadooDynamicEmbeddingProps = {
  hasMargin?: boolean
  leadooSlotScript: string
}

const LeadooDynamicEmbedding = (props: LeadooDynamicEmbeddingProps) => {
  const parsedHTML = Parser(props.leadooSlotScript)

  useEffect(() => {
    function enableLeadooStatistic() {
      if (window?.Cookiebot?.consent?.statistics) {
        console.log("Statistics consent")
        if (!window?.ldanalytics) window.ldanalytics = []
        window.ldanalytics.push(function (a) {
          console.log("Enabling leadoo tracking")
          a.toggleTracking(true, false)
        })
      }
    }

    window.addEventListener("CookiebotOnAccept", enableLeadooStatistic)

    enableLeadooStatistic()
    return () => window.removeEventListener("CookiebotOnAccept", enableLeadooStatistic)
  }, [])

  return <div className={`${props.hasMargin && styles.margins}`}>{parsedHTML}</div>
}

export default LeadooDynamicEmbedding
